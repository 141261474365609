<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Galeri </strong><small>Tambah Data Baru</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputGaleri">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="jdl_galeri"
                  label="Judul Galeri"
                  placeholder="Input Judul Galeri"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="caption_galeri"
                  label="Caption"
                  placeholder="Input Caption"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="credit_galeri"
                  label="Credit Galeri"
                  placeholder="Input Credit Galeri"
                />
              </CCol>
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Kategori</label>
                  <select class="form-control" v-model="id_kategori">
                    <option value="" disabled selected>-- Pilih Kategori --</option>
                    <option v-for="r in kategori" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol>-->
              <CCol sm="6">
                <div class="form-group">
                  <label>File Galeri</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="timestamp"
                  type="date"
                  label="Tanggal Posting"
                  placeholder="Input Tanggal Posting"
                />
              </CCol>
            </CRow>          
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/galeri">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  data () {
    return {
      jdl_galeri : "",
      caption_galeri : "",
      credit_galeri : "",
      timestamp : "",
      gbr_galeri_text : "",
      gbr_galeri : "",
      placeholder : "Pilih File",
      selected : [], // Must be an array reference!      
      show: true,
      modalError: false,
    }
  },
  mounted(){
  // this.loadData();
  },
  
  methods: {

    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"api/public/kategoris/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
    //     .then((response) => {
    //           this.kategori=response.data;
    //     });
    // },

    onFileChange(event) {
      var fileData = event.target.files[0];
      this.gbr_galeri_text = fileData.name;
      this.gbr_galeri = fileData;
      this.placeholder = fileData.name;
    },
    
    inputGaleri: function(event){
      
      const formData = new FormData();

        if(this.gbr_galeri_text){
          formData.append('gbr_galeri', this.gbr_galeri, this.gbr_galeri.name) 
          formData.append('gbr_galeri_text', this.gbr_galeri.name)
        }        
        
        formData.append('jdl_galeri', this.jdl_galeri)
        formData.append('caption_galeri', this.caption_galeri)
        formData.append('credit_galeri', this.credit_galeri)
        formData.append('timestamp', this.timestamp)

      axios.post(process.env.VUE_APP_BASE_URL+"galeri", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/galeri');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>